@import 'src/utils/utils';

.nfound {
  position: relative;
  min-height: calc(100vh - 373px);
  display: grid;
  align-content: start;
  margin-right: 16px;
  margin-left: 16px;
  flex-grow: 1;

  @media(min-width: $md) {
    min-height: calc(100vh - 278px);
  }
  @media(min-width: $lg) {
    min-height: calc(100vh - 195px);
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xl) {
    @include box;
    min-height: calc(100% - 280px);
    grid-template-columns: 140px 1fr;
    column-gap: 30px;
    align-items: center;
    margin-top: 280px;
  }

  &::before {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: auto;
    width: 4px;
    height: 100%;
    content: '';
    background-color: $Corporate-2;

    @media(min-width: $lg) {
      bottom: -100px;
    }
    @media(min-width: $xl) {
      left: 68px;
      right: unset;
      margin: unset;
    }
  }

  &__header {
    @include zero;
    @include Head-45-bold;
    position: relative;
    margin-top: 18px;
    color: $Main-1;
    order: -1;
    text-align: center;
    z-index: 1;
    background-color: $Main-4;

    @media(min-width: $lg) {
      text-align: left;
    }
    @media(min-width: $xl) {
      @include Head-60-bold;
      order: unset;
    }
  }

  &__pig {
    position: relative;
    z-index: 1;
    display: block;
    padding-top: 35px;
    padding-bottom: 15px;
    justify-self: center;
    background-color: $Main-4;

    @media(min-width: $xl) {
      width: 150px;
      height: 150px;
      padding-top: 0;
      padding-bottom: 10px;
    }
  }

  &__plug {
    position: relative;
    z-index: 1;
    display: block;
    width: 56px;
    height: 102px;
    justify-self: center;
    background-color: $Main-4;
  }
}